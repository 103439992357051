import React, { useState, useRef } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { ChevronRight } from 'react-feather'

import Button from '../components/button'


export const TestimonialVideo = ({
  id,
  title,
  showTitle,
  thumbnail,
  className,
  video,
  columnsClassName,
  AfterTitleComponent,
  AfterImageComponent,
  slug,
  projectReference,
  rounded,
  projectsPage,
  responsive,
}) => {
  const videoElement = useRef()
  const [isOpen, updateIsOpen] = useState(false)
  const playVideo = () => {
    updateIsOpen(true)
    try {
      videoElement.current.play()
    } catch (e) {
      console.log('error playing video', 'browser may not support media API')
    }
  }
  return (
    <div
      key={id}
      className={
        !className
          ? `col-12 col-md-6 col-sm-12 p-2 lg:p-3 ${columnsClassName} flex-row items-center mb-3 lg:pb-1 mt-0 pt-0 `
          : className + (columnsClassName ? ' ' + columnsClassName : '')
      }
    >
      {showTitle && (
        <h4 className="block mt-4 mb-3 text-center leading-5">{video.title}</h4>
      )}
      {AfterTitleComponent && <AfterTitleComponent />}
      <div
        className={`${
          projectsPage ? 'shadow-lg' : 'shadow-md'
        } border-radius testimonialboxf`}
      >
        <div
          className={`relative videoWrapper h-full w-full bg-white ${
            rounded
              ? 'border-radius rounded'
              : 'border-radius-top rounded-bl rounded-br'
          }`}
          key={id}
        >
          <span
            className={
              (rounded ? 'border-radius' : 'border-radius-top') +
              ' absolute w-full h-full play-overlay m-auto ' +
              (!isOpen ? 'block' : 'hidden')
            }
            style={{
              zIndex: 10,
              cursor: 'pointer',
              top: '-100%',
              bottom: '-100%',
            }}
            onClick={playVideo}
          >
            <span className="play-button-overlay block">
              <img
                src="/assets/play.svg"
                onClick={playVideo}
                alt="Play Video"
                loading="lazy"
              />
            </span>
          </span>
          <GatsbyImage
            image={getImage(thumbnail)}
            className={`${
              rounded ? 'border-radius' : 'border-radius-top'
            } mb-0`}
            onClick={playVideo}
            alt={`${video.title} Video Testimonial`}
          />
          {isOpen && (
            <video
              ref={videoElement}
              controls={true}
              name="media"
              preloaded="none"
              className={`${
                rounded ? 'border-radius' : 'border-radius-top'
              } w-full ${isOpen ? 'block' : 'hidden'} `}
              poster={thumbnail.gatsbyImageData.images.fallback.src}
              autoPlay={true}
            >
              <source src={video.file.url} type={video.file.contentType} />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
        {AfterImageComponent && <AfterImageComponent />}
        {(projectReference || slug) && (
          <Link
            className="bg-white p-3 text-base text-gray-800 text-center md:mb-0 border-radius-bottom font-semibold flex items-center hover:text-usq-red justify-center z-20 relative leading-none"
            to={
              '/projects/' +
              (projectReference && projectReference.slug
                ? projectReference.slug +
                  (projectReference.slug[projectReference.slug.length - 1] !=
                  '/'
                    ? '/'
                    : '')
                : slug + (slug[slug.length - 1] != '/' ? '/' : ''))
            }
          >
            <span>View Project Photos</span>{' '}
            <ChevronRight size={'18'} strokeWidth={'3'} />
          </Link>
        )}
      </div>
    </div>
  )
}

export const TestimonialSection = ({ testimonial_video_posts }) =>
  testimonial_video_posts.length > 0 && (
    <>
      <section className="p-3 lg:p-0">
        <div className="container lg:pt-4">
          <h3 className="text-center text-black mb-0 mt-2 leading-tight text-2xl">
          Hear From Our Clients
          </h3>
          <div className="row py-4 pt-4 lg:pt-1 pb-0 lg:pb-4">
            {testimonial_video_posts.map((props, i) => (
              <TestimonialVideo
                key={i}
                {...props}
                columnsClassName={
                  testimonial_video_posts.length > 3 ? 'col-lg-3' : 'col-lg-4'
                }
              />
            ))}
          </div>
          
        </div>
      </section>
    </>
  )
