import React from 'react'

class CardGeneral extends React.Component {
  render() {
    return (
      <div
        className={
          'general-card-container border-radius shadow-md sidebar-card' +
          (this.props.className ? ' ' + this.props.className : null)
        }
      >
        {this.props.title ? (
          <div className="sb-title sidebar-title-container">
            <h4>{this.props.title}</h4>
          </div>
        ) : null}
        <div className="sb-content card-body">{this.props.children}</div>
      </div>
    )
  }
}

export default CardGeneral
