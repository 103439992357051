import React, { useState, useEffect, useRef } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import CardGeneral from './card-general'
import {
  Star,
  ArrowLeftCircle,
  ChevronLeft,
  ChevronRight,
  X,
} from 'react-feather'
import Slider from 'react-slick'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import bbb from '../assets/bbb.svg'
import google from '../assets/google_svg.svg'
import yelp from '../assets//yelp_svg.svg'
import fb from '../assets/facebook_svg.svg'
import homeadvisor from '../assets/homeadvisor.svg'
import guildquality from '../assets/guildquality.svg'
import houzz from '../assets/houzz_svg.svg'
import angieslist from '../assets/angieslist.svg'
import Logo from '../components/logo-svg'
import { ReturnSourceFillColor } from '../helpers/reviewSources'
import { TOTAL_REVIEW_COUNT, REVIEW_AVG } from '../consts'
import TimeAgo from 'react-timeago'

const StarsContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    fill: ${props => props.fillColor || '#ffbc00'};
    stroke: none;
  }
`

const AllReviewsContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background: #f9f9f9;
  z-index: 10000;

  width: 55vw;
  left: 22.5vw;
  top: 5vh;
  height: 90vh;
  overflow: hidden;
  border-radius: 0.8em;
  @media (max-width: 992px) {
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 0;
  }
  border-bottom: 1px solid #eee;

  .header-reviews-modal {
    background: #fff;
    position: sticky;
    top: 0;
  }
  .inner-reviews-container {
    height: 100%;
    overflow-y: scroll;
  }
  .logo-container {
    width: 140px;
  }

  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    pointer-events: inherit;
  }
`

const Stars = ({ size, fillColor }) => (
  <StarsContainer fillColor={fillColor ? fillColor : null}>
    <Star size={size ? size : 25} />
    <Star size={size ? size : 25} />
    <Star size={size ? size : 25} />
    <Star size={size ? size : 25} />
    <Star size={size ? size : 25} />
  </StarsContainer>
)

const ReviewSource = styled.span`
  font-weight: normal;
  color: #444;
  display: flex;
  align-items: center;
  img {
    margin-bottom: 0;
  }
`

const ReviewSourceComponent = props => {
  let { source } = props
  if (source == 'homeadvisor')
    return (
      <ReviewSource>
        {' '}
        <img
          style={{ width: '40px', marginRight: '7px' }}
          src={homeadvisor}
          alt="HomeAdvisor 5 Star Rating"
        />
      </ReviewSource>
    )
  if (source == 'yelp')
    return (
      <ReviewSource>
        {' '}
        <img style={{ width: '40px' }} src={yelp} alt="Yelp 5 Star Rating" />
      </ReviewSource>
    )
  if (source == 'guildquality')
    return (
      <ReviewSource>
        {' '}
        <img
          style={{ width: '35px', marginRight: '7px' }}
          src={guildquality}
          alt="GuildQuality 5 Star Rating"
        />
      </ReviewSource>
    )
  if (source == 'houzz')
    return (
      <ReviewSource>
        {' '}
        <img style={{ width: '40px' }} src={houzz} alt="Houzz Rating" />
      </ReviewSource>
    )
  if (source == 'angieslist')
    return (
      <ReviewSource>
        {' '}
        <img
          style={{ width: '35px', marginRight: '7px' }}
          src={angieslist}
          alt="Angie's List A+ Rating"
        />
      </ReviewSource>
    )
  if (source == 'facebook')
    return (
      <ReviewSource>
        {' '}
        <img style={{ width: '40px' }} src={fb} alt="Facebook A+ Rating" />
      </ReviewSource>
    )
  if (source == 'bbb')
    return (
      <ReviewSource>
        <img style={{ width: '40px' }} src={bbb} alt="BBB A+ Rating" />
      </ReviewSource>
    )
  if (source == 'google')
    return (
      <ReviewSource>
        <img
          style={{ width: '40px' }}
          src={google}
          alt="Google 5 Star Rating"
        />
      </ReviewSource>
    )
  else return <ReviewSource>{source}</ReviewSource>
}

export const ReviewForInModal = ({ item, itemKey, projectsPage }) => {
  return (
    <div
      className={`${projectsPage ? '' : 'p-4 shadow-soft '}${
        itemKey % 2 ? '' : 'bg-white'
      }`}
    >
      <div className="flex items-center justify-center">
        {item.node.userPhoto ? (
          <GatsbyImage
            className="user-reviewer-photo rounded-full"
            style={{ width: '45px', height: '45px' }}
            image={getImage(item.node.userPhoto)}
          />
        ) : (
          <img
            className="user-reviewer-photo rounded-full"
            style={{ width: '45px', height: '45px' }}
            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+ZGlzYWJsZWQtLWludmVydGVkPC90aXRsZT48ZyBmaWxsPSIjQUFCN0M0IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxjaXJjbGUgZmlsbC1vcGFjaXR5PSIuMiIgY3g9IjEwIiBjeT0iMTAiIHI9IjEwIi8+PHBhdGggZD0iTTEwIDIwYTkuOTY0IDkuOTY0IDAgMCAxLTcuMjUtMy4xMjVjMS4wNjMtMS45NSAzLjMwNi0zLjI2MiA3LjI1LTMuMjYyczYuMTg4IDEuMzEyIDcuMjUgMy4yNjJBOS45NjUgOS45NjUgMCAwIDEgMTAgMjB6bS4wMDQtMTYuMjMxbC4wMDEtLjAwMmMyLjA3NyAwIDMuNzU5IDEuNTA3IDMuNzU5IDMuOTU3cy0xLjY4MyAzLjk2Mi0zLjc2IDMuOTYyYy0yLjA3NiAwLTMuNzYtMS41MDgtMy43Ni0zLjk1OHMxLjY4NS0zLjk2IDMuNzYtMy45NnoiLz48L2c+PC9zdmc+"
          />
        )}
        <div className="flex justify-between w-full">
          <div>
            <div className="flex items-center img-zero-child">
              <h4 className="mb-0">{item.node.reviewer}</h4>
              <div style={{ width: '25px', marginLeft: '5px' }}>
                <ReviewSourceComponent source={item.node.source} />
              </div>
            </div>

            <span className="text-gray-600 text-sm d-block">
              Posted <TimeAgo date={item.node.date} formatter={(value, unit, suffix) => value + unit + "ago"} />
            </span>
          </div>
          <div>
            <Stars
              size={20}
              fillColor={ReturnSourceFillColor(item.node.source)}
            />
          </div>
        </div>
      </div>

      <p
        className={`text-gray-500 leading-medium text-base mb-0 ${
          projectsPage ? 'mt-4' : 'p-4'
        } reviews-styles`}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: item.node.reviewBody.reviewBody,
          }}
        />
      </p>
      {item.node.reviewImages ? (
        <div className="flex px-4">
          {item.node.reviewImages.map((item, index) => (
            <GatsbyImage
              key={index}
              className="rounded-lg mr-2"
              style={{ width: '100px', height: '100px' }}
              image={getImage(item)}
            />
          ))}
        </div>
      ) : null}
    </div>
  )
}

const ReviewsAllModalPopup = ({
  renderTestimonialsSidebar,
  modalOpen,
  onChange,
}) => {
  const [width, setWidth] = useState(0)

  const toggleReviewsModal = () => {
    onChange(false)
  }

  useEffect(() => {
    const handleResize = () => {
      setWidth(typeof window !== 'undefined' && window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  //listen for when more reviews modal is opened or closed and add/remove class to lock scroll on body
  useEffect(() => {
    // if (modalOpen) {
    //   document.body.classList.add('reviews-open')
    // } else {
    //   document.body.classList.remove('reviews-open')
    // }
  }, [modalOpen, width])

  //on esc key, close reviews modal
  useEffect(() => {
    const handleEsc = event => {
      if (event.keyCode === 27) {
        toggleReviewsModal()
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])

  return (
    <StaticQuery
      query={graphql`  
        query ReviewsAllModalPopup {
          allContentfulTestimonial(
            sort: { fields: id, order: DESC }
            limit: 100
          ) {
            edges {
              node {
                id
                reviewTitle
                # featuredReview
                # date
                reviewer
                # reviewImages {
                #   fixed(width: 150) {
                #     ...GatsbyContentfulFixed
                #   }
                # }
                # userPhoto {
                #   fixed(width: 65) {
                #     ...GatsbyContentfulFixed
                #   }
                # }
                reviewBody {
                  id
                  reviewBody
                }
                # category {
                #   id
                # }
                source
              }
            }
          }
        }
      `}
      render={data => {
        let reviewLimitCategoryID = null
        reviewLimitCategoryID =
          renderTestimonialsSidebar &&
          renderTestimonialsSidebar.testimonialCategory &&
          renderTestimonialsSidebar.testimonialCategory.id

        let filteredReviews = []

        //if a reviewLimitCategoryID is being specified,
        //limit the display to only show reviews with the same category ID

        if (reviewLimitCategoryID) {
          // see if ID exists in the testimonial categories list
          filteredReviews = data.allContentfulTestimonial.edges.filter(function(
            item
          ) {
            return (
              item.node.category.filter(cat => cat.id === reviewLimitCategoryID)
                .length > 0
            )
          })
        } else {
          //no filter, data as is
          filteredReviews = data.allContentfulTestimonial.edges
        }

        return (
          <>
            <div
              className={`modal-bg-overlay ${modalOpen ? 'active' : null}`}
            />

            <AllReviewsContainer className={modalOpen ? 'active' : ''}>
              <div className="header-reviews-modal flex items-center justify-between p-4 shadow-md">
                <div className="flex items-center">
                  <div
                    className="logo-container mr-4"
                    style={{ marginBottom: '-12px' }}
                  >
                    <Logo />
                  </div>

                  <div>
                    <div className="flex items-center">
                      <Stars size={19} />
                      <p className="mb-0">
                        <span className="bold ml-1">{REVIEW_AVG}/5</span>
                      </p>
                    </div>
                    <span className="text-sm italic text-gray-700 d-block -mt-2">
                      Based on {TOTAL_REVIEW_COUNT} reviews
                    </span>
                  </div>
                </div>

                <button
                  style={{ borderWidth: '0' }}
                  className="cursor-pointer d-block border-radius  p-2 f  m0-auto m-0-auto button__ButtonOuter-gFbSyU"
                  onClick={() => toggleReviewsModal()}
                >
                  <X color={'#333'} strokeWidth={'3'} className="d-block" />
                </button>
              </div>

              <div
                className="inner-reviews-container"
                style={{ paddingBottom: '110px' }}
              >
                {filteredReviews.map((item, i) => (
                  <ReviewForInModal item={item} key={i} itemKey={i} />
                ))}
              </div>
            </AllReviewsContainer>
          </>
        )
      }}
    />
  )
}

export default ReviewsAllModalPopup
